<template>
    <div id="main-content" class="bg-white border">
        <BookingComponent :toDayDate="toDayDate" @bookingStatusUpdate="bookingStatusUpdate" :bookings="bookings"
                          :status="bookingStatus"/>
        <pagination v-if="bookings.length>0" :pagination="table.pagination" @paginate="getList" :offset="5"/>
    </div>
    <loading :active="isLoading"
             :can-cancel="false"
             :on-cancel="onCancel"
             :is-full-page="fullPage"></loading>
</template>

<script setup>
import {onMounted, ref}    from 'vue'
import Loading             from 'vue3-loading-overlay';
import BookingComponent    from "@/views/admin/pages/booking/BookingComponent";
import ApiService          from "@/services/api.service";
import NotificationService from "@/services/notification.service";
import Pagination          from "@/components/Pagination";

onMounted(() => {
    getList();
});

var today = new Date();
var dd    = String(today.getDate() + 1).padStart(2, "0");
var mm    = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
var yyyy  = today.getFullYear();

const toDayDate = ref(`${yyyy}-${mm}-${dd}`)

const isLoading = ref(false);

const bookings      = ref([])
const bookingStatus = ref([
    "Pending",
    "Cancelled"
])

const table               = ref({
    search    : '',
    pagination: {
        current_page: 1,
        per_page  : 10,
    }
})
const bookingStatusUpdate = (e, booking) => {
    isLoading.value = true
    let form        = {
        from_frontend: true,
        status       : e.target.value,
        id           : booking.id
    }
    ApiService.post('/bookings-status-update', form).then(({data}) => {
        NotificationService.success(data.message);
        booking.status = e.target.value;
    }).catch(error => {
        NotificationService.error(error.response.data.message);
    }).finally(() => {
        isLoading.value = false
    })
}
const getList             = () => {
    let params = {
        ...table.value,
        per_page: table.value.pagination.per_page,
        page: table.value.pagination.current_page,
    };
    ApiService.get('/bookings', {params: params}).then(({data}) => {
        bookings.value         = data.data
        table.value.pagination = data.meta;
    }).catch(error => {
        NotificationService.error(error.response.data.message);
    })
}
</script>
